import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/max/Documents/node-projects/maxs-portfolio/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vQoHjw1TBWKRIUTZ3j6zYwLd6TuOepXr77DdB1_csz-qmNLTWlnD4T1JiPhqnNgTb-YEbsmPNdR54hh/embed?start=false&loop=false&delayms=5000" frameBorder="0" width="960" height="569" allowFullScreen={true}></iframe>
    <p>{`As part of `}<a parentName="p" {...{
        "href": "https://devpost.com/software/crowdadvisor"
      }}>{`HackUTD VII`}</a>{`, I created `}<a parentName="p" {...{
        "href": "https://financial-analyzer.web.app/"
      }}>{`CrowdAdvisor`}</a>{` with 3 other group members, and we won first place for Capital One's "Best Financial Hack" challenge. CrowdAdvisor presents social media sentiment, most popular news, tweets, and financial information to the user in order to make more informed decisions about investing in stocks and cryptocurrency.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/crowd-advisor/demo1.png",
        "alt": "CrowdAdvisor demo 1"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "/images/crowd-advisor/demo2.png",
        "alt": "CrowdAdvisor demo 2"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "/images/crowd-advisor/demo3.png",
        "alt": "CrowdAdvisor demo 3"
      }}></img></p>
    <p>{`CrowdAdvisor uses Gatsby.js and React for the frontend and Node.js for the backend. We utilized the Twitter API to sourcing popular tweets and the Finnhub API for general stock and crypto information. Our web app runs on Google Firebase Hosting, while our database runs on Firestore. We run the backend on Google Functions in order to periodically update our database and run search queries.`}</p>
    <h2>{`Source`}</h2>
    <p>{`Source code for CrowdAdvisor is available `}<a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/crowd-advisor"
      }}>{`on Github`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      